import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Slider } from "./components/ui/slider";
import { Checkbox } from "./components/ui/checkbox";

const PricingCalculatorWizardAllFeatures = () => {
  const [step, setStep] = useState(0);
  const [airTransactions, setAirTransactions] = useState(0);
  const [hotelTransactions, setHotelTransactions] = useState(0);
  const [estimatedPNRs, setEstimatedPNRs] = useState(0);
  const [tier, setTier] = useState('');
  const [selectedFeatures, setSelectedFeatures] = useState({});

  const [openAccordions, setOpenAccordions] = useState({});

  const features = [
    {
      name: 'Free Module',
      description: 'Essential travel management features included at no cost',
      sections: [
        {
          title: 'Platform',
          items: [
            'Onboarding',
            'Access to app',
            'Access to choice of OBT',
            'Profile management',
            'Traveller mgmt for arrangers',
            'Travel manager permissions',
            'Itinerary mgmt',
            'Safety & risk basic dashboard',
            'My company page personalisation',
            'Announcements',
            'Send message in app',
            'View and download invoices',
            'Export traveller data'
          ]
        },
        {
          title: 'FCM Extension',
          items: [
            'Concur or Cytric as supported OBT',
            'Non-Customizable Notifications (pre-set)',
            'Leakage redirection',
            'CIBT Visa Guidance',
            'Sherpa Travel Guidance',
            '1 Route-based Notification',
            'Unused Credit Notifications',
            'Access via SSO',
            'Support only during initial setup'
          ]
        },
        {
          title: 'Reporting',
          items: [
            'Financial Invoice',
            'Audit Dashboards',
            'Benchmark Dashboards',
            'Financial Seg OD Dashboards',
            'Reservation Dashboards',
            'Traveller Wellness Dashboards',
            'Trends & KPIs'
          ]
        },
        {
          title: 'Online Booking Tool (OBT)',
          items: [
            'Choice of OBT'
          ]
        }
      ],
      prices: [0, 0, 0]
    },
    {
      name: 'FCM Approvals Module',
      description: 'Comprehensive travel approval management system',
      sections: [
        {
          title: 'Features',
          items: [
            'Active approval flow',
            'Passive approval flow',
            'Site redirect',
            'Custom notifications'
          ]
        }
      ],
      prices: [0.50, 0.35, 0.25]
    },
    {
      name: 'Authorisations Module',
      description: 'Pre-booking travel authorisation system',
      sections: [
        {
          title: 'Features',
          items: [
            'Pre-booking travel authorisation',
            'Customizable approval workflows',
            'Integration with booking tools'
          ]
        }
      ],
      prices: [0.50, 0.35, 0.25]
    },
    {
      name: 'The Decision Assist (FCMX)',
      description: 'Advanced travel decision assistance and customization platform',
      sections: [
        {
          title: 'Features',
          items: [
            'Unlimited notifications',
            'Full customization of notifications',
            'Ability to create new notifications',
            'Concur, Cytric, Savi, Deem, and FCMBooking supported',
            'Sustainability guidance (powered by Thrust Carbon)',
            'Access via SSO or Login-less',
            'Ongoing technical support',
            'Quarterly reporting',
            'Leakage Report'
          ]
        }
      ],
      prices: [0.50, 0.40, 0.35]
    },
    {
      name: 'Advanced Reporting (w/ Expense Connector)',
      description: 'Enhanced analytics and reporting capabilities powered by AI with expense integration',
      sections: [
        {
          title: 'Expense Connector',
          items: [
            'Expense Connector Tech Integration'
          ]
        },
        {
          title: 'What if Analysis & Travel Manager Tools',
          items: [
            'Hotel Policy Simulation',
            'Class of Service Simulation',
            'Online Fee Savings Opportunity',
            'Advanced Booking Savings Opportunity',
            'Air Behavioral Dashboard'
          ]
        },
        {
          title: 'AI Reporting',
          items: [
            'Natural language queries'
          ]
        },
        {
          title: 'Flight Status',
          items: [
            'Flight Disruption Risk',
            'Risk and Delay Monitoring and Reporting'
          ]
        },
        {
          title: 'Roadmap',
          items: [
            'NextGen AI (chaptgpt analytics engine)',
            'lowest logical carbon emissions automation',
            'co2 leakage report'
          ]
        }
      ],
      prices: [0.55, 0.45, 0.35]
    },
    {
      name: 'Advanced Reporting (w/o Expense Connector)',
      description: 'Enhanced analytics and reporting capabilities powered by AI',
      sections: [
        {
          title: 'What if Analysis & Travel Manager Tools',
          items: [
            'Hotel Policy Simulation',
            'Class of Service Simulation',
            'Online Fee Savings Opportunity',
            'Advanced Booking Savings Opportunity',
            'Air Behavioral Dashboard'
          ]
        },
        {
          title: 'AI Reporting',
          items: [
            'Natural language queries'
          ]
        },
        {
          title: 'Flight Status',
          items: [
            'Flight Disruption Risk',
            'Risk and Delay Monitoring and Reporting'
          ]
        },
        {
          title: 'Roadmap',
          items: [
            'NextGen AI (chaptgpt analytics engine)',
            'lowest logical carbon emissions automation',
            'co2 leakage report'
          ]
        }
      ],
      prices: [0.35, 0.30, 0.25]
    },
    {
      name: 'Enhanced Data Handoff Module',
      description: 'Advanced data integration and transfer services',
      sections: [
        {
          title: 'Features',
          items: [
            'Automated data transfer',
            'Custom data formats',
            'Real-time synchronization'
          ]
        }
      ],
      prices: [0.20, 0.18, 0.15]
    }
  ];

  useEffect(() => {
    const totalPNRs = airTransactions + (hotelTransactions * 0.6);
    setEstimatedPNRs(Math.round(totalPNRs));
    setTier(calculateTier(totalPNRs));
  }, [airTransactions, hotelTransactions]);

  const calculateTier = (pnrs) => {
    if (pnrs < 10000) return 'Small';
    if (pnrs < 50000) return 'Medium';
    return 'Large';
  };

  const getPrice = (feature) => {
    const tierIndex = tier === 'Small' ? 0 : tier === 'Medium' ? 1 : 2;
    return feature.prices[tierIndex];
  };

  const calculateTotal = () => {
    // First calculate base totals for each feature
    const featureTotals = {};
    Object.entries(selectedFeatures).forEach(([name, selected]) => {
      if (selected && !features.find(f => f.name === name).comingSoon) {
        const feature = features.find(f => f.name === name);
        // Calculate the base price
        const basePrice = getPrice(feature) * (airTransactions + hotelTransactions);
        // Apply minimum price of $1000 if the feature is not free
        featureTotals[name] = feature.prices[0] === 0 ? 0 : Math.max(basePrice, 1000);
      }
    });

    // Apply Approvals + Authorizations bundle discount if both are selected
    const hasApprovals = selectedFeatures['FCM Approvals Module'];
    const hasAuthorizations = selectedFeatures['Authorisations Module'];
    let bundleDiscount = 0;
    
    if (hasApprovals && hasAuthorizations) {
      // Apply 20% discount to both services
      const approvalsDiscount = featureTotals['FCM Approvals Module'] * 0.2;
      const authorizationsDiscount = featureTotals['Authorisations Module'] * 0.2;
      bundleDiscount = approvalsDiscount + authorizationsDiscount;
    }

    // Calculate base total after bundle discount
    const baseTotal = Object.values(featureTotals).reduce((total, amount) => total + amount, 0) - bundleDiscount;

    // Calculate multi-package discount (excluding the bundle discount)
    const selectedCount = Object.values(selectedFeatures).filter(Boolean).length;
    const discountPercentage = Math.max(0, (selectedCount - 1) * 0.05); // 5% for each additional package

    return {
      baseTotal: Object.values(featureTotals).reduce((total, amount) => total + amount, 0),
      bundleDiscount,
      discountPercentage,
      discountedTotal: Math.max(baseTotal * (1 - discountPercentage), 1000), // Ensure final total is not less than $1000
      selectedCount,
      hasApprovalBundle: hasApprovals && hasAuthorizations,
      featureTotals // Add this to help with debugging if needed
    };
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-8">Enter your annual transaction volumes:</h2>
            <div className="mb-8">
              <label className="block mb-4 text-lg font-semibold">Air Transactions:</label>
              <Slider
                className="mb-4"
                min={100}
                max={100000}
                step={100}
                value={[airTransactions]}
                onValueChange={(value) => setAirTransactions(value[0])}
                style={{ "--slider-track-fill-color": "#4CAF50" }}
              />
              <Input
                type="number"
                value={airTransactions}
                onChange={(e) => setAirTransactions(Number(e.target.value))}
              />
            </div>
            <div className="mb-8">
              <label className="block mb-4 text-lg font-semibold">Hotel Transactions:</label>
              <Slider
                className="mb-4"
                min={100}
                max={100000}
                step={100}
                value={[hotelTransactions]}
                onValueChange={(value) => setHotelTransactions(value[0])}
                style={{ "--slider-track-fill-color": "#4CAF50" }}
              />
              <Input
                type="number"
                value={hotelTransactions}
                onChange={(e) => setHotelTransactions(Number(e.target.value))}
              />
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="font-semibold">Estimated PNRs: {estimatedPNRs}</p>
              <p className="font-semibold">Your Category: {tier}</p>
              <p className="text-sm mt-2">Tier Thresholds:</p>
              <ul className="text-sm list-disc list-inside">
                <li>Small: 0 - 9,999 PNRs</li>
                <li>Medium: 10,000 - 49,999 PNRs</li>
                <li>Large: 50,000+ PNRs</li>
              </ul>
            </div>
            <Button onClick={() => setStep(1)}>Next</Button>
          </div>
        );
      case 1:
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Select your features</h2>
            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Multi-Package Discount!</h3>
              <p className="text-sm mb-2">Get a progressive discount when you select multiple packages:</p>
              <ul className="text-sm list-disc list-inside mb-2">
                <li>First package: Full price</li>
                <li>Two packages: 5% off total</li>
                <li>Three packages: 10% off total</li>
                <li>Four packages: 15% off total</li>
                <li>And so on...</li>
              </ul>
              <p className="text-sm text-blue-600 font-semibold">The more you choose, the more you save!</p>
            </div>
            <div className="bg-yellow-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Special Bundle Offer! 🌟</h3>
              <p className="text-sm">Get 20% off both FCM Approvals and Authorisations when you select them together!</p>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <p className="font-semibold">Estimated PNRs: {estimatedPNRs}</p>
              <p className="font-semibold">Your Category: {tier}</p>
              <p className="text-sm mt-2">Tier Thresholds:</p>
              <ul className="text-sm list-disc list-inside">
                <li>Small: 0 - 9,999 PNRs</li>
                <li>Medium: 10,000 - 49,999 PNRs</li>
                <li>Large: 50,000+ PNRs</li>
              </ul>
            </div>
            {features.map((feature) => (
              <div key={feature.name} className="mb-6 border rounded-lg p-4">
                <div className="flex items-start justify-between mb-2">
                  <div className="flex items-start space-x-3">
                    <Checkbox
                      id={feature.name}
                      checked={selectedFeatures[feature.name] || false}
                      onCheckedChange={(checked) =>
                        setSelectedFeatures(prev => ({ ...prev, [feature.name]: checked }))
                      }
                    />
                    <div>
                      <label htmlFor={feature.name} className="font-semibold text-lg">
                        {feature.name}
                      </label>
                      <p className="text-gray-600 mt-1">{feature.description}</p>
                    </div>
                  </div>
                  <div className="text-right text-sm">
                    <div className="font-semibold">Price per PNR:</div>
                    <div className="text-lg font-bold text-blue-600">
                      ${tier === 'Small' ? feature.prices[0] : 
                        tier === 'Medium' ? feature.prices[1] : 
                        feature.prices[2]}
                    </div>
                    <div className="text-gray-500 text-xs">
                      {tier} tier pricing
                    </div>
                  </div>
                </div>
                <div className="ml-8">
                  <button
                    onClick={() => setOpenAccordions(prev => ({
                      ...prev,
                      [feature.name]: !prev[feature.name]
                    }))}
                    className="text-blue-600 hover:text-blue-800 text-sm flex items-center mt-2"
                  >
                    {openAccordions[feature.name] ? 'Hide Details' : 'Show Details'}
                    <svg
                      className={`ml-1 h-4 w-4 transform transition-transform ${
                        openAccordions[feature.name] ? 'rotate-180' : ''
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {openAccordions[feature.name] && (
                    <div className="mt-4 space-y-6">
                      {feature.sections ? (
                        feature.sections.map((section, index) => (
                          <div key={index} className="border-l-4 border-blue-500 pl-4">
                            <h4 className="text-lg font-bold text-blue-700 mb-3 flex items-center">
                              {section.title}
                            </h4>
                            <ul className="list-none space-y-2 ml-2">
                              {section.items.map((item, index) => (
                                <li key={index} className="text-gray-700 flex items-start">
                                  <span className="text-blue-500 mr-2">•</span>
                                  <span>{item}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <ul className="list-disc space-y-2 pl-4">
                          {feature.bulletPoints.map((point, index) => (
                            <li key={index} className="text-gray-700">{point}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
            <Button onClick={() => setStep(2)}>Calculate Total</Button>
          </div>
        );
      case 2:
        const { baseTotal, bundleDiscount, discountPercentage, discountedTotal, selectedCount, hasApprovalBundle } = calculateTotal();
        const quarterlyTotal = discountedTotal / 4;
        const annualDiscountedTotal = discountedTotal * 0.8; // Additional 20% off for annual payment
        return (
          <div>
            <h2 className="text-2xl font-bold mb-4">Your Pricing Summary</h2>
            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Multi-Package Savings</h3>
              <p className="text-sm">You've selected {selectedCount} package{selectedCount !== 1 ? 's' : ''}, 
              {discountPercentage > 0 
                ? ` qualifying for a ${(discountPercentage * 100).toFixed(1)}% discount!` 
                : ' select more packages to unlock discounts!'}</p>
            </div>
            {hasApprovalBundle && (
              <div className="bg-yellow-50 p-4 rounded-lg mb-6">
                <h3 className="font-semibold mb-2">Bundle Savings Applied!</h3>
                <p className="text-sm">You're saving 20% on both FCM Approvals and Authorisations with our bundle offer!</p>
              </div>
            )}
            <div className="bg-green-50 p-4 rounded-lg mb-6">
              <h3 className="font-semibold mb-2">Save More with Annual Payment! 💰</h3>
              <p className="text-sm">Get an additional 20% discount when you pay annually instead of quarterly!</p>
            </div>
            <p className="text-sm text-gray-700 mb-4 font-semibold">
              Choose between quarterly payments or save more with annual payment.
            </p>
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <table className="min-w-full">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Selected Features
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Annual Cost*
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {features.map((feature) => (
                    selectedFeatures[feature.name] && (
                      <tr key={feature.name} className={feature.comingSoon ? "text-gray-400" : ""}>
                        <td className="px-6 py-3">{feature.name} {feature.comingSoon && '(Coming Soon)'}</td>
                        <td className="px-6 py-3 text-right">${(getPrice(feature) * (airTransactions + hotelTransactions)).toFixed(2)}</td>
                      </tr>
                    )
                  ))}
                  <tr className="font-bold">
                    <td className="px-6 py-3">Initial Total</td>
                    <td className="px-6 py-3 text-right">${baseTotal.toFixed(2)}</td>
                  </tr>
                  {bundleDiscount > 0 && (
                    <tr className="text-yellow-600 font-semibold">
                      <td className="px-6 py-3">Approvals Bundle Discount (20%)</td>
                      <td className="px-6 py-3 text-right">-${bundleDiscount.toFixed(1)}</td>
                    </tr>
                  )}
                  {discountPercentage > 0 && (
                    <tr className="text-green-600">
                      <td className="px-6 py-3">Multi-Package Discount ({(discountPercentage * 100).toFixed(1)}%)</td>
                      <td className="px-6 py-3 text-right">-${((baseTotal - bundleDiscount) * discountPercentage).toFixed(1)}</td>
                    </tr>
                  )}
                  <tr className="font-bold text-lg border-t-2">
                    <td className="px-6 py-3">Subtotal after Package Discounts</td>
                    <td className="px-6 py-3 text-right">${discountedTotal.toFixed(2)}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2" className="px-6 py-3 text-xs text-gray-500 italic">
                      * Minimum price per package is $1000 before discounts.
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div className="bg-white p-6 rounded-lg border-2 border-gray-200">
                <h3 className="text-xl font-bold mb-2">Quarterly Payment Plan</h3>
                <p className="text-3xl font-bold text-blue-600 mb-2">${quarterlyTotal.toFixed(2)}</p>
                <p className="text-sm text-gray-600">per quarter</p>
                <p className="text-sm text-gray-600 mt-2">Annual total: ${discountedTotal.toFixed(2)}</p>
              </div>
              <div className="bg-green-50 p-6 rounded-lg border-2 border-green-400">
                <div className="flex items-start justify-between mb-2">
                  <h3 className="text-xl font-bold">Annual Payment</h3>
                  <span className="bg-green-500 text-white text-sm px-2 py-1 rounded">Save 20%</span>
                </div>
                <p className="text-3xl font-bold text-green-600 mb-2">${annualDiscountedTotal.toFixed(2)}</p>
                <p className="text-sm text-gray-600">one-time payment</p>
                <p className="text-sm text-green-600 mt-2">You save: ${(discountedTotal - annualDiscountedTotal).toFixed(2)}</p>
              </div>
            </div>
            <Button onClick={() => setStep(0)}>Start Over</Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="max-w-4xl mx-auto">
      <CardHeader>
        <h1 className="text-3xl font-bold">Travel Tech Pricing Calculator Wizard</h1>
      </CardHeader>
      <CardContent>{renderStep()}</CardContent>
    </Card>
  );
};

export default PricingCalculatorWizardAllFeatures;